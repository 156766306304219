import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_PROMO_CODE, API_RESOURCE_OFFERS, API_RESOURCE_USERS, API_RESOURCE_PRODUCT_CATEGORIES } from 'Consts/apiResources';
import { ADMIN_PROMO_CODES_MANAGE, ADMIN_USER_OFFERS } from 'Consts/routes';
import { 
    PROMO_CODE_LIMIT_TYPES, 
    PROMO_CODE_VALUE_TYPES, 
    PROMO_CODE_USE_LIMIT_TYPES,
    PROMO_CODE_LIMIT_TYPE_OPEN,
    PROMO_CODE_USE_LIMIT_TYPE_TRANSACTION_LIMIT,
    PROMO_CODE_USE_LIMIT_TYPE_OPEN,
    PROMO_CODE_OWNER_TYPES,
    PROMO_CODE_OWNER_TYPE_USER,
} from 'Consts/promoCodes';
import { USER_ROLE_ADEPT } from 'Consts/userRoles';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';
import { getName } from 'Utils/offer';
import { getFullName } from 'Utils/user';
import { toApiFormat as dateToApiFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';
import { TAB_USER_OFFERS, TAB_PAYMENT_TRANSACTIONS } from 'Components/pages/admin/UserOffers/component';

export default class PanelPromoCodesEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            setEnabledStatus: PropTypes.func.isRequired,
            listOffers: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            listProductCategories: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        if (data) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        offerId: data.offer 
            ? {
                value: data.offer.id,
                label: data.offer.displayName,
            }
            : null,
        ownerTypeValue: data.user 
            ? {
                value: data.user.id,
                label: getFullName(data.user).label,
            }
            : null,
        productCategoryId: data.productCategory
            ? {
                value: data.productCategory.id,
                label: data.productCategory.name,
            }
            : null,
    })

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...formState,
            limitType: fromSelectObject(formState.limitType),
            useLimitType: fromSelectObject(formState.useLimitType),
            valueType: fromSelectObject(formState.valueType),
            offerId: fromSelectObject(formState.offerId),
            ownerType: fromSelectObject(formState.ownerType),
            ownerTypeValue: fromSelectObject(formState.ownerTypeValue),
            validTo: dateToApiFormat(formState.validTo),
            productCategoryId: fromSelectObject(formState.productCategoryId),
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_PROMO_CODES_MANAGE.path,
                        { id: response.payload[API_RESOURCE_PROMO_CODE].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            limitType: fromSelectObject(formState.limitType),
            useLimitType: fromSelectObject(formState.useLimitType),
            valueType: fromSelectObject(formState.valueType),
            offerId: fromSelectObject(formState.offerId),
            ownerType: fromSelectObject(formState.ownerType),
            ownerTypeValue: fromSelectObject(formState.ownerTypeValue),
            validTo: dateToApiFormat(formState.validTo),
            productCategoryId: fromSelectObject(formState.productCategoryId),
        });
    }

    render() {
        const { data, location, history, actions } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-promo-codes-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data),
                        label: 'id',
                        value: formState.id,
                    }, {
                        visible: Boolean(data && data.id),
                        label: 'Ilość powiązanych zamówień',
                        value: data && data.usesUserOfferCounter,
                        to: data && withVariables(ADMIN_USER_OFFERS.path, {}, { tab: TAB_USER_OFFERS, promoCodeId: data.id }),
                    }, {
                        visible: Boolean(data && data.id),
                        label: 'Ilość obniżonych transakcji',
                        value: data && data.usesTransactionCounter,
                        to: data && withVariables(ADMIN_USER_OFFERS.path, {}, { tab: TAB_PAYMENT_TRANSACTIONS, promoCodeId: data.id }),
                    }]}
                    controls={[{
                        visible: Boolean(data && data.id && data.enabled),
                        title: 'Wyłącz kod promocyjny',
                        subtitle: 'Kod promocyjny nie będzie mógł zostać uzyty',
                        buttonProps: {
                            onClick: () => actions.setEnabledStatus({ id: data && data.id, enabled: false }),
                            children: 'Wyłącz',
                        },
                    }, {
                        visible: Boolean(data && data.id && !data.enabled),
                        title: 'Włącz kod promocyjny',
                        subtitle: 'Kod promocyjny będzie mógł zostać uzyty',
                        buttonProps: {
                            onClick: () => actions.setEnabledStatus({ id: data && data.id, enabled: true }),
                            children: 'Włącz',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'code',
                            label: 'Kod',
                        }, {
                            type: 'datePicker',
                            name: 'validTo',
                            label: 'Data ważności',
                        }, {
                            type: 'select',
                            name: 'offerId',
                            label: 'Oferta',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listOffers({
                                    search: query,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_OFFERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getName(element, true).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'productCategoryId',
                            label: 'Kategoria produku',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listProductCategories({
                                    search: query,
                                    enabled: true,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_PRODUCT_CATEGORIES].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: element.name,
                                })
                            },
                        }, {
                            type: 'select',
                            name: 'ownerType',
                            label: 'Typ właściciela',
                            options: PROMO_CODE_OWNER_TYPES.map(type => ({
                                value: type.key,
                                label: type.label,
                            })),                            
                        }, {
                            type: 'select',
                            name: 'ownerTypeValue',
                            label: 'Użytkownik',
                            isVisible: Boolean(formState.ownerType && fromSelectObject(formState.ownerType) === PROMO_CODE_OWNER_TYPE_USER),
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_ADEPT,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'limitType',
                            label: 'Typ limitu łącznych wykorzystań w całej aplikacji',
                            options: PROMO_CODE_LIMIT_TYPES.map(type => ({
                                value: type.key,
                                label: type.label,
                            })),
                        }, {
                            isVisible: fromSelectObject(formState.limitType) !== PROMO_CODE_LIMIT_TYPE_OPEN,
                            type: 'input',
                            name: 'limitValue',
                            label: 'Wartość limitu łącznych wykorzystań w całej aplikacji',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'select',
                            name: 'useLimitType',
                            label: 'Typ limitu łącznych wykorzystań w pojedynczym zamówieniu',
                            options: PROMO_CODE_USE_LIMIT_TYPES.map(type => ({
                                value: type.key,
                                label: type.label,
                            })),
                        }, {
                            isVisible: Boolean(formState.useLimitType && fromSelectObject(formState.useLimitType) !== PROMO_CODE_USE_LIMIT_TYPE_OPEN),
                            type: 'input',
                            name: 'useLimitValue',
                            label: formState.useLimitType && fromSelectObject(formState.useLimitType) === PROMO_CODE_USE_LIMIT_TYPE_TRANSACTION_LIMIT
                                ? 'Ilość pierwszych płatności w zamówieniu podlegających rabatowi' 
                                : 'Wartość limitu',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'input',
                            name: 'userUseLimit',
                            label: 'Limit wykorzystań dla pojedynczego użytkownika (0 oznacza brak limitu)',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'select',
                            name: 'valueType',
                            label: 'Typ wartości',
                            options: PROMO_CODE_VALUE_TYPES.map(valueType => ({
                                value: valueType.key,
                                label: valueType.label,
                            })),
                        }, {
                            type: 'input',
                            name: 'value',
                            label: 'Wartość',
                            inputProps: {
                                type: 'number',
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}