import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    setEnabledStatus,
} from 'Redux/modules/admin/promoCodes/actions';
import {
    list as listOffers,
} from 'Redux/modules/admin/offers/actions';
import {
    list as listUsers,
} from 'Redux/modules/admin/users/actions';
import {
    list as listProductCategories,
} from 'Redux/modules/admin/productCategories/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            setEnabledStatus: dispatch(setEnabledStatus),
            listOffers: dispatch(listOffers),
            listUsers: dispatch(listUsers),
            listProductCategories: dispatch(listProductCategories),
        },
    }),
)(Component);